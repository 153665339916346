import store from '@/store';
import {GraphQLClient, request as fetchGQL} from 'graphql-request';

export function queryMe() {
    let endpoint = store.state.url_backend
    let habilitado = true

    const client = new GraphQLClient(endpoint)
    return client.rawRequest(/* GraphQL */ `
    query{
        me{
            empresa{
                habilitada
            }
        }
    }`,
    {

    },
    {
        authorization: `Bearer ${ localStorage.getItem('user_token') }`
    }).then((data) => {
        //  console.log(data)
        if (data.data.me.empresa.habilitada) {
            localStorage.setItem('habilitado', data.data.me.empresa.habilitada)
            habilitado = true
        } else {
            localStorage.clear()
            habilitado = false
        }
        return habilitado

    })


}