<template>

    <section class="section container columns is-centered  pt-4 ">
        <div class="column  is-half-desktop is-mobile ">
            <div class="field columns has-text-centered">
                <div v-show="isMobile" class="column ">
                    <label class="label pt-2 has-text-center">Nombre <span style="color: red">*</span></label>
                </div>
                <div v-show="!isMobile" class="column ">
                    <label class="label pt-2 has-text-right">Nombre <span style="color: red">*</span></label>
                </div>
                <div class="control column  is-flex-grow-0  ">
                    <input v-model="nombre" name="nombre" class="input" type="text" >
                    <p v-show="message_error.nombre_error != ''" class="help is-danger">{{message_error.nombre_error}}</p>
                </div>
            </div>

            <div class="field  columns has-text-centered">
                <div v-show="isMobile" class="column ">
                    <label class="label pt-2 has-text-center">Codigo Postal</label>
                </div>
                <div v-show="!isMobile" class="column ">
                    <label class="label pt-2 has-text-right">Codigo Postal</label>
                </div>
                <div class="control column is-flex-grow-0  ">
                    <input v-model="codigo_postal" name="codigo_postal" class="input " type="text" >
                </div>
            </div>



            <div class="field columns has-text-centered">

                <div v-show="isMobile" class="column ">
                    <label class="label pt-2 has-text-center">Provincia</label>
                </div>
                <div v-show="!isMobile" class="column ">
                    <label class="label pt-2 has-text-right">Provincia</label>
                </div>
                <div class="control column is-flex-grow-0  ">
                    <input v-model="provincia" name="provincia" class="input " type="text" >
                </div>
            </div>

            <div class="field  columns has-text-centered">
            <div v-show="isMobile" class="column ">
                    <label class="label pt-2 has-text-center">Pais</label>
                </div>
                <div v-show="!isMobile" class="column ">
                    <label class="label pt-2 has-text-right">Pais</label>
                </div>
                <div class="control column is-flex-grow-0  ">
                    <input v-model="pais" name="pais" class="input " type="text" >
                </div>
            </div>

            <!-- Vista para el movi -->
            <div v-show="!isMobile" class="field columns ">
                <div class="column  has-text-right ">
                    <button class="button  has-text-right" style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>
                <div v-show="!cargando" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success " style="width: 17.1rem;" @click="validar">
                        Agregar nueva ciudad
                    </button>
                </div>
                <div v-show="cargando" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success is-loading" style="width: 17.1rem;"></button>
                </div>
            </div>
            <div v-show="isMobile" class="field columns has-text-centered">
                <div v-show="!cargando" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success " style="width: 17.1rem;" @click="validar">
                        Agregar nueva ciudad
                    </button>
                </div>
                <div v-show="cargando" class="control column   is-flex-grow-0 ">
                    <button class="button  is-success is-loading" style="width: 17.1rem;"></button>
                </div>
                <div class="column  has-text-center ">
                    <button class="button mr-2  " style=" background-color: #DD2923 ; color:white;" @click="volver">
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
        <div>
            <div class="modal" :class="{'is-active': mostrar_error}">
                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}" >
                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->

                        <p class="has-text-centered has-text-danger">No se completaron los campos requeridos</p>

                        <div class="columns mt-2">
                            <div class="column">
                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_error = false">Esta bien</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { inject, ref } from 'vue';
import { GraphQLClient, gql } from 'graphql-request'
import { useRouter } from 'vue-router';
import store from '@/store'
import {queryMe} from '@/composables/queryMe.js'


export default {
    name:'NuevaCiudad',

    setup(){
        const isMobile = inject('isMobile')
        const error = ref(false)
        const message_error = ref([ {nombre_error: ''}])
        const nombre = ref('')
        const codigo_postal = ref('')
        const provincia = ref('')
        const pais = ref('')
        const router = useRouter()
        const endpoint = store.state.url_backend
        const cargando = ref(false)
        const mostrar_error = ref(false)


        const volver = () => {
            router.go(-1)
        }

        const validar = async () => {
            cargando.value = true
            let habilitado = await queryMe()
            if (habilitado) {
                validando()
            } else {
                router.push({name: 'Login'})
            }
        }

        const validando = () => {
            error.value = false
            message_error.value.nombre_error = ''

            if (nombre.value == '') {
                error.value = true
                message_error.value.nombre_error = 'El campo nombre no puede estar vacio'
            }
            if (codigo_postal.value == '') {
                codigo_postal.value = ''
            }
            if (provincia.value == '') {
                provincia.value = ''
            }
            if (pais.value == '') {
                pais.value = ''
            }

            if (error.value) {
                cargando.value = false
                mostrar_error.value = true

            } else {

                registrar()
            }
        }

        const registrar = () => {
            const client = new GraphQLClient(endpoint)


            // Estructura FetchQL(url, query, variable, opcions)
            client.rawRequest(/* GraphQL */ `
            mutation($nombre:String!, $codigopostal: String!, $provincia: String!, $pais:String!){
               creaMunicipio(input: {
                        nombre: $nombre,
                        provincia: $provincia,
                        pais: $pais,
                        codigopostal: $codigopostal
                    }){
                        id,
                        nombre,
                        codigopostal,
                        provincia,
                        pais
                    }
            }`,
            {
                nombre: nombre.value,
                codigopostal: codigo_postal.value,
                provincia: provincia.value,
                pais: pais.value,
            },
            {

                authorization: `Bearer ${ localStorage.getItem('user_token') }`

            })
            .then((data) => {

                router.push({name: 'TableroCiudades'})
                let accion = "nuevaciudad"
                store.commit('verificar_carga',accion)

            }).catch(error => {
            //   console.log(error.response);

            })
        }


        return{
            isMobile ,
            error ,
            message_error ,
            nombre,
            codigo_postal,
            provincia,
            pais,
            router ,
            cargando,
            volver,
            validar,
            mostrar_error,
        }

    }
}
</script>

<style scoped>

.input{

        width: 17.1rem;

}



</style>